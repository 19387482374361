<template>
  <div class="shop-cart device-reserve">
    <Header />
    <div class="container">
      <div class="w-1200">
        <div class="shop-cart-main" v-loading="loading">
          <section class="device-list">
            <div
              class="device-list-item"
              v-for="(company, index) in deviceInfo"
              :key="index"
            >
              <div class="device-list-header">
                <img v-if="company.item[0].consumerHead == null || company.item[0].consumerHead == '' " src="~@/assets/images/temp/temp_04.png"  >
                <img v-else
                  :src="company.item[0].consumerHead"
                  class="avatar"
                  alt=""
                />
                {{ company.company_name }}
              </div>
              <div class="device-info-list">
                <div
                  class="device-info-item"
                  v-for="(item, index) in company.item"
                  :key="index"
                >
                  <img :src="item.showImg" class="pic" alt="" />
                  <div class="content">
                    <div class="name">
                      {{ item.name }}
                    </div>
                    <div class="tag">
                      {{ item.type_name }}
                    </div>
                  </div>
                  <div><small>¥ </small>{{ item.rental_fee }}</div>
                  <div class="ml40">
                    租赁时长（{{ item.rental_type }}）：{{ item.rental_time }}
                  </div>
                  <div class="ml40">租赁台数：{{ item.rental_num }}</div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="reserve-info">
          <div class="reserve-info-title">确认预定信息</div>
          <div class="reserve-info-main">
            <div class="left-area">
              <div class="reserve-info-item">
                <span class="label">项目名称：</span>
                <el-input
                  type="text"
                  placeholder="请填写您的项目名称"
                  style="padding-right: 25px"
                  v-model="reserveInfo.itemName"
                >
                </el-input>
              </div>
              <div class="reserve-info-item">
                <span class="label">用工地址：</span>
                <el-input
                  type="text"
                  id="test"
                  placeholder="请填写您的用工地址"
                  v-model="reserveInfo.address"
                >
                </el-input>
                <div
                  id="searchResultPanel"
                  style="
                    border: 1px solid #c0c0c0;
                    width: 500px;
                    height: auto;
                    display: none;
                  "
                ></div>
                <el-button
                  type="primary"
                  style="margin-left: 10px"
                  @click="$refs.map.setPlace(reserveInfo.address)"
                  >定位</el-button
                >
                <i
                  class="f-icon icon-location-orange"
                  style="margin-top: 12px"
                ></i>
              </div>
              <div class="reserve-info-item">
                <span class="label">租赁日期：</span>
                <el-date-picker
                  style="width: 500px"
                  v-model="reserveInfo.time"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>

                <i class="f-icon icon-cal" style="margin-top: 12px"></i>
              </div>
              <div class="reserve-info-item">
                <span class="label">留言备注：</span>
                <el-input
                  type="textarea"
                  :rows="3"
                  placeholder="请填写您的备注内容…"
                  v-model="reserveInfo.remark"
                  style="padding-right: 25px"
                >
                </el-input>
              </div>
            </div>
            <div class="map">
              <Map
                ref="map"
                @getAddress="getAddress"
                :searchAddress="reserveInfo.address"
              ></Map>
            </div>
          </div>
        </div>

        <div class="total-cost">
          预期费用：<small>¥</small><big>{{ sum }}</big>
        </div>
        <section class="form-btn-group">
          <span class="btn-item btn-border" @click="$router.go(-1)">取消</span>
          <span class="btn-item" @click="apply">立即申请</span>
        </section>
      </div>
    </div>
    <Footer />
    <right-nav />
  </div>
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import RightNav from "@/components/rightNav";
import { device } from "@/api/shopCart";
import Map from "@/components/map";
import kits from "@/kits/index";
export default {
  components: {
    Header,
    Footer,
    RightNav,
    Map,
  },
  data() {
    return {
      textarea: "",
      deviceInfo: [],
      reserveInfo: {
        itemName: "",
        address: "",
        time: [],
        remark: "",
      },
      sum: 0,
      loading: false,
    };
  },
  mounted() {
    this.getDeviceInfo();
    this.searchResult();
  },
  watch: {
    deviceInfo: {
      // 监听的对象数组
      handler(newVal) {
        this.sum = 0;
        console.log("watch", newVal);
        newVal.forEach((company) => {
          company.sum = 0;
          if (company.item) {
            company.item.forEach((item) => {
              item.rental_type = item.rental_fee.split("/")[1];
              item.sum =
                parseFloat(item.rental_fee.split("/")[0]) *
                item.rental_num *
                item.rental_time;
              company.sum += item.sum;
            });
          }
          this.sum += company.sum;
        });
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    getAddress(address) {
      this.reserveInfo.address = address;
    },
    //转化type类型
    type(type) {
      let numType = 0;
      switch (type) {
        case "日":
          numType = 0;
          break;
        case "月":
          numType = 1;
          break;
        case "半年":
          numType = 2;
          break;
        case "年":
          numType = 3;
          break;
        default:
          break;
      }
      return numType;
    },
    //获取设备信息
    getDeviceInfo() {
      this.loading = true;
      this.deviceInfo = JSON.parse(localStorage.getItem("deviceReserve_Key"));
      this.deviceInfo.forEach((order) => {
        order.item.forEach((item) => {
          if (item.consumerHead && item.showImg) {
            return;
          }
          item.consumerHead = kits.img.url(item.consumer_head);
          item.showImg = kits.img.url(item.show_img);
        });
      });
      this.loading = false;
      console.log("deviceInfo", this.deviceInfo);
    },
    //申请
    apply() {
      //整理参数
      let orders = [];
      this.deviceInfo.forEach((company) => {
        company.item.forEach((item) => {
          console.log("item", item);

          let companyObj = {
            seller_consumer_id: item.consumer_id,
            remark: this.reserveInfo.remark,
            address: this.reserveInfo.address,
            rental_time_start: this.reserveInfo.time[0],
            rental_time_end: this.reserveInfo.time[1],
            rental_time_day: this.DateDiff(
              this.reserveInfo.time[1],
              this.reserveInfo.time[0]
            ),
            name: this.reserveInfo.itemName,
            longitude: this.$refs.map.coordinates.lng,
            latitude: this.$refs.map.coordinates.lat,
            order_info: [],
          };

          company.item.forEach((item) => {
            let order_info_obj = {
              device_lease_id: item.device_lease_id,
              show_img: item.show_img,
              num: item.rental_num,
              rental_time: item.rental_time,
              rental_type: this.type(item.rental_fee.split("/")[1]),
              consumer_id: item.consumer_id,
              // rental_fee: parseFloat(
              //     item.rental_fee.split("/")[0]
              // ),
            };
            companyObj.order_info.push(order_info_obj);
          });

          console.log("111companyObj", companyObj);
          orders.push(companyObj);
        });
      });

      let id = [];
      let arr = [];
      for (let i = 0; i < orders.length; i++) {
        if (id.indexOf(orders[i].seller_consumer_id) == -1) {
          id.push(orders[i].seller_consumer_id);
          arr.push(orders[i]);
        }
      }
      orders = arr;
      console.log("orders", orders);

      //提示
      if (
        !this.reserveInfo.itemName.trim() ||
        !this.reserveInfo.address.trim() ||
        this.reserveInfo.time.length === 0
      ) {
        this.$message({
          message: "预定信息不完整",
          type: "error",
        });
      } else {
        if (this.sum > 99999999) {
          this.$message({
            message: "预定金额不超过8位数",
            type: "error",
          });
        } else {
          device({
            inputs: {
              orders: orders,
            },
          })
            .then((res) => {
              console.log(res);
              if (res.status === 200) {
                this.$message({
                  message: "预定成功",
                  type: "success",
                });
                this.$router.push({ name: "myOrder" });
              } else if (res.status === 500) {
                this.$message({
                  message: "未知错误，请联系客服",
                  type: "error",
                });
              } else {
                this.$message({
                  message: "预定失败",
                  type: "error",
                });
              }
            })
            .catch((err) => {
              this.$message({
                message: `${err}`,
                type: "error",
              });
            });
        }
      }
    },
    //计算日期天数
    DateDiff(Date_end, Date_start) {
      if ((Date_end, Date_start)) {
        let aDate, oDate1, oDate2, iDays;
        Date_end = Date_end.split(" "); //将时间以空格划分为两个数组  第一个数组是 2019-05-20 第二个数组是 00：00：00
        aDate = Date_end[0].split("-"); //获取第一个数组的值
        oDate1 = new Date(aDate[0], aDate[1], aDate[2]); //将前半个数组以-拆分，每一个是一个数值
        Date_start = Date_start.split(" ");
        aDate = Date_start[0].split("-");
        oDate2 = new Date(aDate[0], aDate[1], aDate[2]);
        iDays = parseInt(Math.abs(oDate1 - oDate2) / 1000 / 60 / 60 / 24); //把相差的毫秒数转换为天数
        return iDays;
      }
    },
    searchResult() {
      console.log("searchResult``````````````````");
      var ac = new window.BMapGL.Autocomplete({ input: "test" });
      console.log("ac", ac);
      let that = this;
      ac.addEventListener("onhighlight", function (e) {
        //鼠标放在下拉列表上的事件
        var str = "";
        var _value = e.fromitem.value;
        var value = "";
        if (e.fromitem.index > -1) {
          value =
            _value.province +
            _value.city +
            _value.district +
            _value.street +
            _value.business;
        }
        str =
          "FromItem<br />index = " +
          e.fromitem.index +
          "<br />value = " +
          value;
        value = "";
        if (e.toitem.index > -1) {
          _value = e.toitem.value;
          value =
            _value.province +
            _value.city +
            _value.district +
            _value.street +
            _value.business;
        }
        str +=
          "<br />ToItem<br />index = " +
          e.toitem.index +
          "<br />value = " +
          value;
        document.getElementById("searchResultPanel").innerHTML = str;
      });

      var myValue;
      ac.addEventListener("onconfirm", function (e) {
        //鼠标点击下拉列表后的事件
        document.getElementById("searchResultPanel").innerHTML = "";
        var _value = e.item.value;
        myValue =
          _value.province +
          _value.city +
          _value.district +
          _value.street +
          _value.business;
        document.getElementById("searchResultPanel").innerHTML =
          "onconfirm<br />index = " +
          e.item.index +
          "<br />myValue = " +
          myValue;
        that.$refs.map.setPlace(myValue);
      });
    },
  },
};
</script>

<style scoped>
.device-reserve .reserve-info .reserve-info-main .map {
  height: 295px;
}
</style>
